.Home
    // height 100%
    // box-shadow 0 0 4px black
    padding-left 0
    padding-right 0

    .Home-body
        // display flex
        // flex-direction row
        // width 100%
        // margin 0
        padding 0 15px

        .overview-body
            padding-top 20px
            flex 0 0 1170px

        .sidebar
            flex 1 1 500px

.Home-body
    background white
    // box

.Home-nav
    display flex
    flex-flow row
    align-items center
    justify-content center
    background rgb(220, 220, 220)
    border 1px solid rgb(180, 180, 180)
    border-left none
    border-right none

    a
        font-size 1.2em
        color rgb(120, 120, 120)
        text-shadow 0 1px 0 rgb(240, 240, 240)
        flex auto auto 30px
        padding 10px 15px
        text-align center

        &.active
            background rgb(195, 195, 195)
            color rgb(80, 80, 80)
            box-shadow inset 0 1px 8px grey

        &:hover, &:focus
            text-decoration none
            color rgb(80, 80, 80)

.Home-content
    padding-top 15px
    padding-bottom 15px

.overview-header-container
    display block
    background-color #ededed
    position relative

    .header-image-container
        max-height 300px
        max-width 900px
        margin-right auto
        margin-left auto

        .header-image
            background-image url('/static/img/cover.png')
            display block
            max-width 900px
            max-height 300px
            background-color #ededed
            background-size contain
            background-repeat no-repeat
            background-position center
            padding-top 33.3333%

.or-spacer
    width 100%
    position absolute
    bottom 0
    left 0
    .mask
        overflow hidden
        height 20px

    .mask:after
        content ''
        display block
        margin 20px auto 0
        width 100%
        height 25px
        border-radius 125px / 12px
        box-shadow 0 0 8px black
