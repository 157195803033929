.MapOverview-background
    position fixed
    top 0
    left -8px
    right -8px
    display block
    z-index -1
    height 100%
    background-position top
    background-repeat no-repeat
    background-size cover
    filter blur(8px)
    transition opacity 0.2s ease-in
