$tier-1-color := rgb(220, 220, 220)
$tier-2-color := rgb(130, 240, 240)
$tier-3-color := rgb(100, 230, 100)
$tier-4-color := rgb(235, 235, 40)
$tier-5-color := rgb(255, 160, 0)
$tier-6-color := rgb(255, 125, 125)

// TODO: Finish this up for small viewports
.classtabs
    margin-left 8px
    z-index 99
    .classtab
        display inline-block
        background black
        margin-right 8px
        padding 4px 8px
        border-radius 4px 4px 0 0
        text-shadow 1px 1px black
        border 1px solid #6f8d8c
        border-color #6f8d8c #6f8d8c rgba(0, 0, 0, 0.0) #6f8d8c
        background linear-gradient(135deg,
                           darken(rgba(45,70,95,0.8), 10%),
                           darken(rgba(64,123,130,0.8), 20%),
                           darken(rgba(31,120,130,0.8), 10%)
                           )
        transform translateY(1px)

.MapOverview-LeaderboardContainer-Leaderboard
    position relative
    // min-width 200px

    > .loading-overlay
        z-index 3
        position absolute
        height 100%
        width 100%
        background rgba(0, 0, 0, 0.5)
        border-radius 4px


    > .panel-heading
        position sticky
        top 41px
        z-index 2

    // .panel-body
    //     position relative

    .load-more-button
        display block
        width 100%
        padding 4px
        border-color transparent
        // background linear-gradient(135deg, rgba(41,63,86,0.5), rgba(51,98,104,0.5), rgba(28,108,117,0.5))
        // box-shadow 0 1px 2px rgba(0, 0, 0, 0.8)
        border-radius 4px
        border-width 1px
        background none

        &:hover
            box-shadow none
            background linear-gradient(135deg, rgba(41,63,86,0.6), rgba(51,98,104,0.6), rgba(28,108,117,0.6))
            border-color rgba(0, 0, 0, 0.6)
            text-decoration none

        &:active
            background linear-gradient(180deg, rgba(41,63,86,0.6), rgba(51,98,104,0.6), rgba(28,108,117,0.6))

        &:focus
            outline none

        &:disabled
            background rgba(255, 255, 255, 0.2)
            color rgb(180, 180, 180)
            box-shadow none

            &:hover
                text-decoration none
                // cursor not-allowed
