.MapOverview-header
    position relative
    margin-bottom 15px
    text-shadow 1px 1px 0 black
    box-shadow 0 2px 4px black
    z-index 100
    text-align center
    // soft gradient
    background-color rgb(32, 48, 64)
    background-image linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.2),
        transparent
    )
    background linear-gradient(135deg,
                               rgb(32, 50, 68),
                               rgb(46, 89, 94),
                               rgb(32, 50, 68)
                               )
    background linear-gradient(
        to bottom,
        rgba(70, 100, 125, 1.0),
        rgba(50, 60, 70, 1.0)
    )
    background-clip padding-box

    &:before
        pointer-events none
        content ''
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        background-image linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0.5) calc(50% - 400px),
                rgba(255, 255, 255, 0) calc(50% - 80px),
                rgba(255, 255, 255, 0) calc(50% + 80px),
                rgba(0, 0, 0, 0.5) calc(50% + 400px),
                rgba(0, 0, 0, 0.6) 100%)

    .header-title
        // stripe overlay
        background: repeating-linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.05),
            transparent 2px,
            transparent 60px,
            rgba(0, 0, 0, 0.4) 60px,
            rgba(0, 0, 0, 0.25) 64px,
            rgba(0, 0, 0, 0.15) 116px,
            rgba(0, 0, 0, 0.15) 120px
        )
        background: repeating-linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.04),
            transparent 2px,
            transparent 90px,
            rgba(0, 0, 0, 0.30) 90px,
            rgba(0, 0, 0, 0.12) 92px,
            rgba(0, 0, 0, 0.06) 96px,
            rgba(0, 0, 0, 0.06) 100px
        )
        background-position center
        background-size cover
        // background: repeating-linear-gradient(
        //     150deg,
        //     transparent,
        //     transparent 30px,
        //     rgba(0, 0, 0, 0.2) 30px,
        //     rgba(0, 0, 0, 0.2) 60px
        // ) left, repeating-linear-gradient(
        //     -150deg,
        //     transparent,
        //     transparent 30px,
        //     rgba(0, 0, 0, 0.2) 30px,
        //     rgba(0, 0, 0, 0.2) 60px
        // ) right
        // background-size 50% 100%
        // background-repeat no-repeat
        border-bottom 1px solid rgb(66, 81, 92)

        .header-title-inner
            // horizontal fade-in-out overlay
            // background-image linear-gradient(
            //     90deg,
            //     rgba(0, 0, 0, 0.4) 20%,
            //     rgba(255, 255, 255, 0.05) 50%,
            //     rgba(0, 0, 0, 0.4) 80%)
            padding 0 30px
            // border-bottom 1px solid rgb(86, 104, 118)

        .header-container
            padding-top 8px

        h1
            margin-top 0
            font-weight bold
            margin-bottom 0
            text-shadow 3px 3px 0 rgba(0, 0, 0, 0.65)

            a
                color inherit
                &:hover
                    color rgb(220, 220, 220)

            small
                color rgb(180, 180, 180)
                text-shadow 2px 2px 0 rgba(0, 0, 0, 0.65)

    .MapOverview-sub-header
        padding 6px 0

    .MapOverview-header-video-buttons
        display block
        position absolute
        right 0
        bottom 0

        .fa-youtube
            font-size 32px
            vertical-align middle

        .video-button
            cursor pointer

            &.disabled
                opacity 0.3
                cursor not-allowed

    .map-info-list-container
        display inline-block
        background linear-gradient(135deg,
                                   rgb(50, 50, 50),
                                   rgb(80, 80, 80),
                                   rgb(90, 90, 90)
                                   )
        background linear-gradient(
            to bottom,
            rgb(64, 64, 64),
            rgb(90, 90, 90)
        )
        box-shadow 1px 1px 4px inset rgba(0, 0, 0, 0.7)
        border 1px solid rgb(66, 81, 92)
        border-bottom-color rgba(255, 255, 255, 0.05)
        margin-top 8px
        margin-bottom -1px
        border-radius 8px 8px 0 0
        border-bottom 0

    .map-info-list
        padding 3px 20px
        list-style none
        margin-bottom 0
        border-radius 8px 8px 0 0
        // background radial-gradient(rgba(0, 0, 0, 0.25) 15%, transparent 16%) 0 0,
        //            radial-gradient(rgba(0, 0, 0, 0.25) 15%, transparent 16%) 8px 8px,
        //            radial-gradient(rgba(255, 255, 255, 0.09) 15%, transparent 20%) 0 1px,
        //            radial-gradient(rgba(255, 255, 255, 0.09) 15%, transparent 20%) 8px 9px
        background-size 16px 16px

        li
            display inline
            vertical-align middle

            .tf-icon
                vertical-align text-top

            &:not(:first-child):before
                content: ' | '
