.RecordOverview
    padding-top 10px
    background white

    .steamavatar-container
        float left
        margin-right 8px

    h1
        display inline-block
        margin-top 0

    h4
        display inline-block
        margin 0


    .info-panel
        padding 15px
        margin-bottom 20px
        border-radius 4px
        background-color white
        box-shadow 0 1px 2px rgba(0, 0, 0, .05)
        border 1px solid #ddd
        h5
            margin-top 0
            font-size 16px

    .info-table
        // margin-bottom 10px

        td
            padding 4px
            vertical-align middle

            &.info-label
                font-weight bold
                text-align right

            &.duration
                font-family Droid Sans Mono
