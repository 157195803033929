.PlayerLeaderboards
    padding-top 10px
    background white

.player-ranks-icon
    font-size 43px
    width 48px
    vertical-align middle

.player-ranks-table-container
    // height 447px
    // overflow-y scroll
    margin-bottom 20px

    table
        margin-bottom 0

.rank-user-icon
    font-size 20px
    width 24px
    vertical-align middle
