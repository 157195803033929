html, body
    height 100%

body

    // background-color #F9F9F9
    background-color rgb(55, 70, 80)
    font-family Noto Sans,Helvetica Neue,Helvetica,Arial,sans-serif

h1, h2, h3
    font-family Noto Serif,Helvetica Neue,Helvetica,Arial,sans-serif

    &.page-title
        margin-top 0

table
    td.shrink
        white-space nowrap
    td.expand
        width 99%

    th.shrink
        white-space nowrap
    th.expand
        width 99%

.App
    display flex
    flex-direction column
    min-height 100vh

    .content
        flex 1
        position relative
        display flex

.App-container
    flex 1
    // display flex
    padding-top 41px
    // padding-bottom 15px
    min-height 100%

    &.solid
        padding-top 56px
        // background-color #F9F9F9
        background white

#navbar-main
    background #222
    // background rgb(40, 40, 40)
    border-bottom 1px solid black
    min-height 40px
    box-shadow 0 0 4px black

    .navbar-brand
        height 40px
        padding 10px
        font-family 'Montserrat'
        color rgb(0, 180, 220)
        &:hover
            color rgb(0, 220, 250)

    .navbar-nav
        & > li > a
            padding 10px 15px

    .navbar-toggle
        margin-top 3px
        margin-bottom 3px

.navbar-login-dropdown
    margin-left 8px

    > ul > li
        padding 0

        & > a
            &:hover, &:focus
                background none

.navbar-login-container
    // margin-left 32px

    // .login-button
    //     padding 8px

    // .dropdown-toggle
    //     padding 8px
    padding 0 8px !important

    & > a.dropdown-toggle
        padding 8px 15px !important

    .avatar-container
        .steamavatar
            border-width 1px
            height 24px
            width 24px

        .playername
            margin-left 4px
            vertical-align middle
            display inline-block
            max-width 130px
            text-overflow ellipsis
            overflow hidden
            white-space nowrap

    &:hover
        color white

.app-search-form
    margin 0

.app-search-container
    position relative
    display inline-block
    margin 0

    .app-search-input
        padding 10px 15px
        height auto
        width 180px
        border-radius 0
        background rgb(15, 15, 15)
        outline 0
        border 0
        color #9d9d9d

        &:focus
            box-shadow none
            color rgb(240, 240, 240)

    ul.app-search-results
        border-radius 0 !important
        min-width 300px
        max-height 400px
        overflow-y auto
        position absolute
        top calc(100% + 1px)
        right 0
        & li:first-child
            border-radius 0

        // li
        //     padding 8px 12px
        //     min-width 80px
        // & li:first-child
        //     border-top-left-radius 0
        //     border-top-right-radius 0

.table-vertical-align
    & td
        vertical-align middle !important

.app-tooltip
    position absolute
    z-index 1070
    display block

.capitalize
    text-transform capitalize

.small-input
    padding 3px 6px
    height auto

.panel-dark
  border-color rgba(255, 255, 255, 0.2)
  border-width 2px
  box-shadow 1px 1px 4px rgba(0, 0, 0, 0.65)
  text-shadow 1px 1px 0 rgba(0, 0, 0, 0.65)
  background none
  border-radius 0

  a
    color rgb(0, 218, 255)

    &:hover
        color rgb(0, 240, 255)
        text-decoration underline

  & > .panel-body
    background-color rgba(20, 30, 40, 0.90)
    // background-color darken(rgba(51, 98, 104, 0.9), 50%)
    // background-color rgba(40, 40, 40, 0.9)
    border-bottom-left-radius 0
    border-bottom-right-radius 0


  & > .panel-heading
    padding 8px 12px
    color rgb(240, 240, 240)
    // background-image linear-gradient(135deg,
    //                            rgba(41, 63, 86, 0.9),
    //                            rgba(51, 98, 104, 0.9),
    //                            rgba(28, 108, 117, 0.9)
    //                            )
    background-color rgba(32, 48, 64, 0.9)
    background-image linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.2),
        transparent
    )
    background linear-gradient(135deg,
                               rgba(46, 89, 94, 0.9),
                               rgba(32, 50, 68, 0.9),
                               rgba(46, 89, 94, 0.9)
                               )
    background linear-gradient(
        135deg,
        rgba(70, 100, 125, 0.9),
        rgba(50, 60, 70, 0.9)
    )
    box-shadow 0 4px 3px -3px black
    border-bottom 1px solid rgba(255, 255, 255, 0.1)
    border-bottom none
    border-radius 0

    + .panel-collapse > .panel-body
      border-top-color rgba(200, 200, 200, 0.8)

    .badge
      background-color rgba(255, 255, 255, 0.3)

  & > .panel-footer
      border-color rgba(255, 255, 255, 0.25)
      background-color rgba(10, 20, 20, 0.7)

  & > .panel-footer
    + .panel-collapse > .panel-body
      border-bottom-color rgba(200, 200, 200, 0.8)

.nav-pills.nav-dark
    > li > a
        color rgb(230, 230, 230)

    > li.active > a
        display block
        background rgba(255, 255, 255, 0.3)


.btn-default.btn-dark
    background linear-gradient(to bottom,rgba(200, 200, 200, 0.5) 0,rgba(170, 170, 170, 0.5) 100%)
    color rgb(240, 240, 240)
    text-shadow 0 1px black
    border none
    // text-align: left
    // background: rgba(255, 255, 255, 0.3)
    // border-radius: 4px
    // border: none
    // text-shadow: 1px 1px #080808

    &.active
        background rgba(150, 150, 150, 0.5)

.btn-primary.btn-dark
    background-image linear-gradient(hsla(197, 52%, 37%, 1) 0px, rgb(24, 62, 78) 100%)
    background-color rgb(24, 62, 78)
    // box-shadow 0 1px 3px rgba(0, 0, 0, 0.9)
    box-shadow 1px 1px 2px black
    border-color rgb(24, 62, 78)

    &:hover
        background-color rgb(24, 62, 78)

    &:active:focus
        background-color rgb(24, 62, 78)

.form-row
    display flex
    flex-wrap wrap
    margin-left -5px
    margin-right -5px

    > .col
        padding-left 5px
        padding-right 5px
        max-width 100%
        flex-grow 1
        flex-basis 0

.flex-row
    display flex
    align-items flex-start
    padding-left 15px
    padding-right 15px

    > .col
        flex 1 0
        padding-left 15px
        padding-right 15px
