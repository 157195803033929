.DemoInfo
    margin-bottom 10px

    td
        padding 4px
        vertical-align middle

        &.info-label
            font-weight bold
            text-align right
