.DemoOverview
    padding-top 10px
    background white

    h1
        display inline-block

    .demo-download-container
        font-size 24px
        line-height 1.1
        vertical-align top
