.MapOverview
    // padding-top 16px
    padding-left 0
    padding-right 0
    // box-shadow 0 0 2px black
    position relative
    // background rgba(255, 255, 255, 0.2)
    color rgb(230, 230, 230)
    // background black

// .MapOverview-body
//     padding 0 15px 0 0
//     padding-top 15px
//     padding-left 0 !important
//     padding-right 0 !important
//     margin-top -15px
//     margin-left 15px
//     margin-right 15px
//     // background-image repeating-linear-gradient(
//     //     -45deg,
//     //     rgba(255, 255, 255, 0.02) 10px,
//     //     rgba(0, 0, 0, 0.08) 12px,
//     //     rgba(0, 0, 0, 0.04) 30px,
//     //     rgba(0, 0, 0, 0.04) 40px,
//     //     rgba(0, 0, 0, 0.02) 50px
//     // )
