.MapOverview-Overview
    .panel-body
        // border-radius 0 !important
        padding 0

        .inner-body
            display flex
            padding 15px

    .panel-footer
        background rgba(20, 30, 40, 0.9)
        padding 0
        border-bottom-left-radius 0
        border-bottom-right-radius 0
        overflow hidden

        .leaderboard-link
            display block
            padding 8px
            background rgba(40, 60, 80, 0.5)
            color rgb(0, 218, 255)

            text-align center
            text-shadow 0 1px black
            font-weight bold

            &:hover
                color rgb(0, 240, 255)
                text-decoration underline
                background-image linear-gradient(
                    to bottom,
                    rgba(0, 200, 255, 0.1),
                    rgba(0, 80, 100, 0.05)
                )
