.tf-icon
    background-size cover
    display inline-block
    vertical-align middle

    &.auto
        height 1.42857143em
        width 1.42857143em
        vertical-align text-top

    &.mini
        width 24px
        height 24px

    &.sm
        width 28px
        height 28px

    &.medium
        width 32px
        height 32px

    &.large
        width 48px
        height 48px

    &.demoman
        background-image url('/static/img/icons/demoman.png')

    &.soldier
        background-image url('/static/img/icons/soldier.png')
