.MapOverview-LeaderboardContainer-Leaderboard-AddRunModal
    .run-form
        .player-selection
            // border-radius 4px
            // border 1px solid rgb(202, 203, 205)
            // color rgb(220, 220, 220)
            // font-weight bold
            padding-right 8px

            .player
                display block
                // background rgb(100, 100, 100)
                border-radius 14px
                // box-shadow -1px 0 0 black
                // > a
                //     color #00b4f0

            button.remove
                border none
                background none
                font-weight bold
                // line-height 28px
                // color inherit
                padding 0

            .steamavatar
                margin-right 2px
                border-width 1px
                border-color inherit
                border-radius 50%
                height 26px
                width 26px
                background rgb(78, 78, 78)
                box-shadow 0 0 2px rgba(0, 0, 0, 0.8)

            .steamid
                line-height 26px
                vertical-align middle
//     position fixed
//     top 0
//     left 0
//     width 100%
//     height 100%

//     background rgba(0, 0, 0, 0.5)

    // z-index 1000
