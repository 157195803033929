.MapOverview-LeaderboardTable
    position relative
    width calc(100% + 30px)
    max-width calc(100% + 30px)
    margin-top -15px
    margin-left -15px
    margin-right -15px
    margin-bottom 15px
    border-collapse: separate
    border-top 0

    & > thead > tr > th
        position sticky
        top 97px
        padding 5px
        border-bottom-color rgba(255, 255, 255, 0.2)
        border-bottom-width 2px
        border-bottom 2px solid rgb(90, 90, 90)
        font-weight normal
        background rgba(20, 30, 40, 0.90)
        user-select none
        color rgb(210, 210, 210)
        font-weight bold
        z-index 1

        &:first-child
            padding-left 15px

        &:last-child
            padding-right 15px

        &.rank, &.date
            text-align right

        &.duration
            padding 5px 14px

        &.sortable
            &:hover
                // text-decoration underline
                color rgb(240, 240, 240)
                background rgba(100, 100, 100, 0.8)

            cursor pointer

        &.selected
            border-bottom-color rgb(160, 160, 160)

.MapOverview-LeaderboardTable-container
    position relative

    // Sticky table header shadow
    // Workaround for lack of sticky support on <tr>s in Chrome
    .table-container
        position relative

        > .shadow-container
            position absolute
            top 0
            left -15px
            right -15px
            height 100%
            z-index 2
            pointer-events none

            > .header-shadow
                position sticky
                top 97px
                height 32px
                box-shadow 0 4px 4px -4px black

    .leaderboard-buttons
        position sticky
        bottom -1px
        margin-left -15px
        margin-right -15px
        margin-bottom 15px
        padding 2px 15px
        background rgba(20, 30, 40, 0.90)
        background-clip padding-box
        border-top 1px solid rgba(255, 255, 255, 0.25)
        border-bottom 1px solid rgba(255, 255, 255, 0.25)
        box-shadow 0 -3px 3px -3px black, 0 3px 3px -3px black
        text-align right

