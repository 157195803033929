.Overview-WorldRecord
    .worldrecord-container
        position relative
        padding-bottom 18px
        margin 0 15px
        // border-radius 16px 40px 16px 16px
        // background rgba(255, 255, 255, 0.3)
        // border 2px solid rgba(255, 255, 255, 0.2)

        .SteamAvatarMedal {
            margin-left 16px
            display inline-block
            flex 0 0 auto
        }

        .steamavatar-image-container .steamavatar.small {
            height: 44px;
            width: 44px;
            border-radius: 50%;
            border-width: 3px;
            box-shadow: 0 0 0 1px rgb(30, 30, 30),
                        1px 1px 3px 1px rgb(30, 30, 30);
            // box-shadow: 1px 1px 0 0 #656565,
            //             2px 2px 0 0 #4c4c4c,
            //             3px 3px 0 0 #333333,
            //             3px 3px 8px 0 black
        }

        .info-container
            display flex
            flex 1 0 auto
            align-items flex-start
            min-width 140px
            padding 6px
            background rgba(105, 105, 105, 0.4)
            // border-radius 12px 30px 12px 12px
            border-radius 30px
            border 2px solid rgba(0, 0, 0, 0.62)
            border-bottom-width 1px;
            border-right-width 1px;
            box-shadow 1px 1px 3px 0px #1f1f1f inset,
                       0px 0px 4px #d4d4d4

            .avatar-link-container
                &:hover + .player-link-container .player-link
                    color #00f0ff
                    text-decoration underline

            .player-link-container
                margin-left: 6px;

            .player-link
                display block
                font-weight bold
                font-size 1.2em

            .run-info-item
                > .icon
                    // &:before
                    // display inline-block
                    // content ''
                    // width 2px
                    // height 1em
                    // vertical-align middle
                    // background rgb(160, 160, 160)
                    // margin-right 4px
                    // margin-left 30px
                    // box-shadow 1px 1px 0 black
                    width 28px
                    // height 24px
                    // font-size 14px
                    // padding 5px
                    margin-right 6px
                    text-align right
                    color rgb(160, 160, 160)
                    // font-size 0.9em
                    // vertical-align: middle
                    // font-size 16px


            .run-duration
                font-weight bold
                font-family Droid Sans Mono, monospace
                font-size 15px
                a
                    color inherit
                    &:hover
                        color rgb(255, 255, 255)

            .run-date
                position absolute
                left 8px
                bottom 0
                font-size 0.8em
                text-shadow 0 1px 2px black
                color rgb(192, 192, 192)
                user-select none
