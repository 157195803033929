.Home-Servers-ServerItem
    border-top 1px solid #ddd

    td
        vertical-align middle !important

    .name .flag-container
        margin-right 8px
        box-shadow 0 0 4px rgb(180, 180, 180)
        display inline-block
        position relative
        vertical-align middle

        .flag-icon
            display inline-block
            height 24px
            width 32px
            vertical-align middle

    .status
        text-align right
        text-transform uppercase

        &.offline
            color rgb(255, 50, 50)

        &.online
            color rgb(0,150,0)

        a
            color inherit
