.Maps-MapListFancy-MapListItem
    position relative
    display inline-block
    height 90px
    min-width 320px
    flex 1 1 auto
    margin 1px
    background-color rgb(55, 70, 80)
    font-size 18px
    transition font-size .1s ease-out

    color white
    font-weight bold

    &:hover, &:focus
        color white
        opacity 1.0
        cursor pointer

        .item-inner
            .tiers
                opacity 1

            .name-container
                top 15%
                height 1.777777778em

            .tiers
                bottom 18px

    .item-background
        width 100%
        height 90px
        position absolute
        top 0
        left 0
        background-position center
        background-size cover
        transition opacity 0.5s

    .name-container
        width 100%
        height 100%
        position absolute
        top 0
        left 0
        transition top 0.2s ease, height 0.2s ease
        background-color rgba(0, 0, 0, 0.5)
        padding 4px 16px
        text-align center

        .name
            position absolute
            top 46%
            left 50%
            transform translate(-50%, -50%)

    .tiers
        position absolute
        bottom 0
        left 50%
        transform translateX(-50%)
        transition bottom 0.2s ease, opacity 0.2s ease
        margin-top 4px
        opacity 0
        font-size 12px

        .tier
            font-family tahoma
            background rgba(0, 0, 0, 0.5)
            padding 1px 6px 5px 6px
            border-radius 8px
            vertica

            .tier-inner
                vertical-align middle

            .tf-icon
                height 18px
                width 18px
                margin-right 4px

        .tier-0
            color rgb(255,75,75)

    .item-inner
        display block
        height 100%
        transition background 0.2s
