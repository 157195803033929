.MapOverview-Authors
    // max-width 600px
    ul
        list-style none
        padding-left 0

        li
            // display inline-block
            margin-bottom 4px
            // margin-right 4px

            .author-link
                display block
                // width 100%
                padding-right 16px
                border-radius 24px
                box-shadow 0 0 4px rgba(0, 0, 0, 0.5)
                line-height 32px
                font-weight bold
                overflow hidden
                white-space nowrap
                text-overflow ellipsis
                background-image linear-gradient(45deg, rgba(255, 255, 255, 0.2) , rgba(255, 255, 255, 0.1) )

                &:hover
                    background rgba(255, 255, 255, 0.2)

                .map-count
                    float right
                    color rgba(255, 255, 255, 0.7)
                    font-size 90%

            .steamavatar.small
                font-size inherit
                height 32px
                width 32px
                border-radius 50%
                box-shadow 0 0 4px rgba(0, 0, 0, 0.5)

            .currentname
                font-weight normal
