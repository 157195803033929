fontBaseline = 14px

redRibbon() {
    content: '';
    background: black;
    background-clip: padding-box;
    position: absolute;
    height: (48 / fontBaseline)em;
    bottom: -(20 / fontBaseline)em;
    border-left-color: crimson;
    border-right-color: rgb(152, 11, 37);
    border-width: (8 / fontBaseline)em;
    border-bottom-color: transparent;
    border-bottom-width: (6 / fontBaseline)em;
    border-style: solid;
    filter: drop-shadow(0 (1 / fontBaseline)em (1 / fontBaseline)em black);
}

.SteamAvatarMedal {
    position: relative;
    display: inline-block;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
    font-size: 14px;

    > .ribbons {
        &:before {
            redRibbon()
            left: (18 / fontBaseline)em;
            bottom: -(24 / fontBaseline)em;
            transform: rotate(-8deg);
        }

        &:after {
            redRibbon()
            left: (4 / fontBaseline)em;
            transform: rotate(8deg);
        }
    }

    > .cogs {
        position relative

        > .cog {
            transform-origin: bottom;
            position: absolute;
            border: (5 / fontBaseline)em solid goldenrod;
            border-bottom-color: transparent;
            border-top-color: transparent;
            border-top-width: (1 / fontBaseline)em;
            border-bottom-width: (1 / fontBaseline)em;
            height: (25 / fontBaseline)em;
            left: (15 / fontBaseline)em;
            top: -(5 / fontBaseline)em;
            filter: drop-shadow(0 0 (2 / fontBaseline)em black);
        }
    }

    & .tf-icon-container {
        display: block;
        position: relative;
        padding: (5 / fontBaseline)em;
        border-radius: 50%;
        background: rgb(200, 200, 200);
        box-shadow: 1px 1px 3px 1px rgb(40, 40, 40) inset;

        .tf-icon {
            display: block;
            height: (30 / fontBaseline)em;
            width: (30 / fontBaseline)em;
        }
    }
}
