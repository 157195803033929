.steamavatar-image-container
    position relative
    display inline-block

    .steamavatar
        display inline-block

        border-width 1px
        border-style solid
        // border-radius 4px
        // outline 1px solid black
        // box-shadow 0 2px 4px grey
        // outline 1px solid black
        transition border-color 0.1s ease-out, opacity 0.1s ease-out

        &.steamavatar-image
            opacity 0
            position absolute
            left 0
            top 0
            // border-color transparent

        &.steamavatar-default
            opacity 100

        &.tiny
            border-radius 0
            // border 0
            width 24px
            height 24px

        &.mini
            border-width 2px
            box-shadow 0 0 1px black
            width 28px
            height 28px

        &.small
            border-width 2px
            width 38px
            height 38px

        &.medium
            border-width 2px
            // border-radius 8px
            width 68px
            height 68px

        &.mediumlarge
            width 120px
            height 120px
            border-width 3px

        &.large
            border-width 4px
            width 188px
            height 188px

        &.offline
            border-color #706c6b

        &.online
            border-color #7bafd6

        &.in-game
            border-color #9bc861

        &.inner-border
            padding 1px

            &.offline
                background darken(#706c6b, 40%)

            &.online
                background darken(#706c6b, 40%)

            &.in-game
                background darken(#706c6b, 40%)
