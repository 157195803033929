.PlayerOverview
    background white
    // padding-top 15px
    padding-left 0
    padding-right 0
    // min-height 1000px
    box-shadow 0 0 4px black

.player-overview-header-container
    background linear-gradient(135deg,
                               darken(rgb(45, 70, 95), 35%),
                               darken(rgb(64, 123, 130), 35%),
                               darken(rgb(31, 120, 130), 35%)
                               )

.player-overview-header
    position relative
    padding 15px 15px
    color rgba(255, 255, 255, 0.9)
    background-image: repeating-linear-gradient(
            -45deg,
            transparent,
            transparent 60px,
            rgba(0, 0, 0, 0.25) 60px,
            rgba(0, 0, 0, 0.2) 90px,
            rgba(0, 0, 0, 0.25) 120px
        );
    text-shadow 0 1px 1px black, 0 1px 1px black
    // background darken(rgb(55, 90, 110), 60%)
    // background linear-gradient(
    //     lighten(rgb(55, 70, 80), 95%),
    //     lighten(rgb(55, 70, 80), 85%)
    // )

    border-bottom 1px solid darken(rgb(55, 90, 110), 60%)
    // margin-left -10px
    // margin-right -10px
    box-shadow 0 0 16px black

    .steamavatar
        box-shadow 0 0 2px black
        // border-color black
        // border 1px solid black

    .last-seen
        float right
        text-align right

.player-overview-tabs
    ul
        padding-left 15px
        padding-right 15px

.player-overview-class-selection
    display flex
    flex-direction row
    // margin-top 15px
    // padding-bottom 15px
    // border-bottom 1px solid #ddd
    box-shadow 0 4px 4px -4px grey

    a:first-child
        border-left 0
        // display none

    a:last-child
        border-right 0

    a
        font-size 16px
        flex 1 1 0
        padding 5px
        border-radius 0
        text-align center
        color rgb(100, 100, 100)
        background rgb(230, 230, 230)
        background linear-gradient(
            rgb(250, 250, 250),
            rgb(220, 220, 220)
        )
        border 1px solid rgb(180, 180, 180)
        border-left 0

        &.active
            background rgb(215, 215, 215)
            color rgb(70, 70, 70)

        &:hover, &:focus
            text-decoration none
            color rgb(70, 70, 70)

            .tf-icon
                opacity 1.0

        .fa
            vertical-align middle

        .tf-icon
            vertical-align middle
            opacity 0.8

        .title
            font-size 15px
            vertical-align middle

        .fa
            line-height 32px
            font-size 24px

.player-overview-body
    margin-top 20px
    padding-left 15px
    padding-right 15px

.player-overview-avatar
    float left
    margin-right 12px

.player-overview-header-content
    h2
        margin-top 0
        text-shadow 2px 2px 1px black

.player-overview-stats
    padding-left 0
    // margin-bottom 0
    li
        list-style none

    .player-overview-stats-flag
        display inline-block
        text-align center
        width 24px

.player-overview-stats-icon
    position absolute
    right 8px
    top 6px
    opacity 0.4
    // font-size 48px
    color black

    .fa
        font-size 32px

    .tf-icon
        width 32px
        height 32px

    &:hover
        color black
        opacity 0.7

.site-panel
    position relative
    box-shadow 0 1px 2px rgba(0,0,0,.05)
    background-color #fff
    border 1px solid #ddd
    border-radius 4px
    margin-bottom 20px
    padding 15px

    h4, h3
        margin-top 0

.player-overview-class-container
    background-position right 6px top 6px
    background-repeat no-repeat
    background-size 48px
    padding 0

    &.soldier
        background-image url("/static/img/icons/soldier.png")

    &.demoman
        background-image url("/static/img/icons/demoman.png")

    & .inner
        padding 15px
        height 100%
        background-color rgba(255, 255 255, 0.7)
