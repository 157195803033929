html,
body {
  height: 100%;
}
body {
  background-color: #374650;
  font-family: Noto Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}
h1,
h2,
h3 {
  font-family: Noto Serif, Helvetica Neue, Helvetica, Arial, sans-serif;
}
h1.page-title,
h2.page-title,
h3.page-title {
  margin-top: 0;
}
table td.shrink {
  white-space: nowrap;
}
table td.expand {
  width: 99%;
}
table th.shrink {
  white-space: nowrap;
}
table th.expand {
  width: 99%;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.App .content {
  flex: 1;
  position: relative;
  display: flex;
}
.App-container {
  flex: 1;
  padding-top: 41px;
  min-height: 100%;
}
.App-container.solid {
  padding-top: 56px;
  background: #fff;
}
#navbar-main {
  background: #222;
  border-bottom: 1px solid #000;
  min-height: 40px;
  box-shadow: 0 0 4px #000;
}
#navbar-main .navbar-brand {
  height: 40px;
  padding: 10px;
  font-family: 'Montserrat';
  color: #00b4dc;
}
#navbar-main .navbar-brand:hover {
  color: #00dcfa;
}
#navbar-main .navbar-nav > li > a {
  padding: 10px 15px;
}
#navbar-main .navbar-toggle {
  margin-top: 3px;
  margin-bottom: 3px;
}
.navbar-login-dropdown {
  margin-left: 8px;
}
.navbar-login-dropdown > ul > li {
  padding: 0;
}
.navbar-login-dropdown > ul > li > a:hover,
.navbar-login-dropdown > ul > li > a:focus {
  background: none;
}
.navbar-login-container {
  padding: 0 8px !important;
}
.navbar-login-container > a.dropdown-toggle {
  padding: 8px 15px !important;
}
.navbar-login-container .avatar-container .steamavatar {
  border-width: 1px;
  height: 24px;
  width: 24px;
}
.navbar-login-container .avatar-container .playername {
  margin-left: 4px;
  vertical-align: middle;
  display: inline-block;
  max-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.navbar-login-container:hover {
  color: #fff;
}
.app-search-form {
  margin: 0;
}
.app-search-container {
  position: relative;
  display: inline-block;
  margin: 0;
}
.app-search-container .app-search-input {
  padding: 10px 15px;
  height: auto;
  width: 180px;
  border-radius: 0;
  background: #0f0f0f;
  outline: 0;
  border: 0;
  color: #9d9d9d;
}
.app-search-container .app-search-input:focus {
  box-shadow: none;
  color: #f0f0f0;
}
.app-search-container ul.app-search-results {
  border-radius: 0 !important;
  min-width: 300px;
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
}
.app-search-container ul.app-search-results li:first-child {
  border-radius: 0;
}
.table-vertical-align td {
  vertical-align: middle !important;
}
.app-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
}
.capitalize {
  text-transform: capitalize;
}
.small-input {
  padding: 3px 6px;
  height: auto;
}
.panel-dark {
  border-color: rgba(255,255,255,0.2);
  border-width: 2px;
  box-shadow: 1px 1px 4px rgba(0,0,0,0.65);
  text-shadow: 1px 1px 0 rgba(0,0,0,0.65);
  background: none;
  border-radius: 0;
}
.panel-dark a {
  color: #00daff;
}
.panel-dark a:hover {
  color: #00f0ff;
  text-decoration: underline;
}
.panel-dark > .panel-body {
  background-color: rgba(20,30,40,0.9);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.panel-dark > .panel-heading {
  padding: 8px 12px;
  color: #f0f0f0;
  background-color: rgba(32,48,64,0.9);
  background-image: linear-gradient(135deg, rgba(255,255,255,0.2), transparent);
  background: linear-gradient(135deg, rgba(46,89,94,0.9), rgba(32,50,68,0.9), rgba(46,89,94,0.9));
  background: linear-gradient(135deg, rgba(70,100,125,0.9), rgba(50,60,70,0.9));
  box-shadow: 0 4px 3px -3px #000;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  border-bottom: none;
  border-radius: 0;
}
.panel-dark > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: rgba(200,200,200,0.8);
}
.panel-dark > .panel-heading .badge {
  background-color: rgba(255,255,255,0.3);
}
.panel-dark > .panel-footer {
  border-color: rgba(255,255,255,0.25);
  background-color: rgba(10,20,20,0.7);
}
.panel-dark > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: rgba(200,200,200,0.8);
}
.nav-pills.nav-dark > li > a {
  color: #e6e6e6;
}
.nav-pills.nav-dark > li.active > a {
  display: block;
  background: rgba(255,255,255,0.3);
}
.btn-default.btn-dark {
  background: linear-gradient(to bottom, rgba(200,200,200,0.5) 0, rgba(170,170,170,0.5) 100%);
  color: #f0f0f0;
  text-shadow: 0 1px #000;
  border: none;
}
.btn-default.btn-dark.active {
  background: rgba(150,150,150,0.5);
}
.btn-primary.btn-dark {
  background-image: linear-gradient(#2d748f 0px, #183e4e 100%);
  background-color: #183e4e;
  box-shadow: 1px 1px 2px #000;
  border-color: #183e4e;
}
.btn-primary.btn-dark:hover {
  background-color: #183e4e;
}
.btn-primary.btn-dark:active:focus {
  background-color: #183e4e;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.form-row > .col {
  padding-left: 5px;
  padding-right: 5px;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}
.flex-row {
  display: flex;
  align-items: flex-start;
  padding-left: 15px;
  padding-right: 15px;
}
.flex-row > .col {
  flex: 1 0;
  padding-left: 15px;
  padding-right: 15px;
}
/*# sourceMappingURL=src/scenes/App/styles.css.map */