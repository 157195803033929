.Home-Servers
    // padding 12px 12px 0

    .servers-inner
        box-shadow 0 0 4px black
        background white
        // background rgba(255, 255, 255, 0.2)

    .row
        padding 4px
        // background repeating-linear-gradient(
        //   45deg,
        //   rgb(150, 150, 150),
        //   rgb(150, 150, 150) 10px,
        //   rgb(220, 220, 220) 10px,
        //   rgb(220, 220, 220) 20px
        // );

    th.status
        text-align right

.Home-Servers-list
    width 100%
    margin-bottom 5px

    .Home-Servers-Server:not(:last-child)
        border-bottom 1px solid #ddd
