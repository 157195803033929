.MapOverview-LeaderboardContainer-Leaderboard-PersonalRecord
    margin-top 15px
    background linear-gradient(135deg, rgba(41,63,86,0.8), rgba(51,98,104,0.8), rgba(28,108,117,0.8))

    &.panel-dark
        box-shadow none
        border none

    .panel-heading, .panel-body
        padding 4px 8px

    .steamavatar
        // display none
        height 28px
        width 28px
        border-radius 50%
        border-width 2px
        box-shadow 0 0 2px black
